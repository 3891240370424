// import './App.css';
import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';
import React, { useState, useEffect } from "react";
import 'reactjs-popup/dist/index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useRouteMatch,
  useParams,
  BrowserRouter
} from "react-router-dom";

import Home from './components/Home';
import WalletTrackerAppHome from './components/WalletTracker/WalletTrackerAppHome';


function getLibrary(provider) {
  return new Web3(provider)
}

function App() {
  const [errorMessage, setErrorMessage] = useState('');
  return (
   <BrowserRouter>
      <Routes>
          <Route path="/" element={<Web3ReactProvider getLibrary={getLibrary}><Home errorMessage={errorMessage}/></Web3ReactProvider>}></Route>
          <Route path="/app" element={<Web3ReactProvider getLibrary={getLibrary}><WalletTrackerAppHome setErrorMessage={setErrorMessage}/></Web3ReactProvider>}></Route>       
      </Routes>   
    </BrowserRouter>
  );
}



export default App;