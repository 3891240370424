import './Home.css'
import About from "./AboutPage/about"
import Card from "./CardPage/Card"
import ReactFullpage from "@fullpage/react-fullpage"
import RoadMap from './RoadMap/Roadmap'
import lobby from '../assets/videos/RoadMap/desktop/growth map main page.mp4'
import r1 from '../assets/videos/RoadMap/desktop/phase1.mp4'
import r2 from '../assets/videos/RoadMap/desktop/phase2.mp4'
import r3 from '../assets/videos/RoadMap/desktop/phase3.mp4'
import r4 from '../assets/videos/RoadMap/desktop/service creation.mp4'
import lobbymobile from '../assets/videos/RoadMap/mobile/growth map main page.mp4'
import r1mb from '../assets/videos/RoadMap/mobile/phase1.mp4'
import r2mb from '../assets/videos/RoadMap/mobile/phase2.mp4'
import r3mb from '../assets/videos/RoadMap/mobile/phase3.mp4'
import r4mb from '../assets/videos/RoadMap/mobile/service creation.mp4'


export default function Home({ errorMessage }) {
    const anchors = ["About", "Card", "RoadMap-1","RoadMap-2","RoadMap-3","RoadMap-4","RoadMap-5"];
    return (
        <>
            <ReactFullpage
                anchors={anchors}
                navigation
                navigationTooltips={anchors}
                sectionsColor={["black", "black", "black"]}
                onLeave={(origin, destination, direction) => {
                    console.log("onLeave event", { origin, destination, direction });
                }}
                render={({ state, fullpageApi }) => {
                    console.log("render prop change", state, fullpageApi); // eslint-disable-line no-console

                    return (
                       <>
                            <div className="section">
                                <About errorMessage={errorMessage}></About>
                            </div>
                            <div className="section">
                                <Card></Card>
                            </div>
                            <div className="section">
                                <RoadMap mobileSource={lobbymobile} videoSource={lobby} ></RoadMap>
                            </div>
                            <div className="section">
                                <RoadMap mobileSource={r1mb} videoSource={r1} ></RoadMap>
                            </div>
                            <div className="section">
                                <RoadMap mobileSource={r2mb} videoSource={r2} ></RoadMap>
                            </div>
                            <div className="section">
                                <RoadMap mobileSource={r3mb} videoSource={r3} ></RoadMap>
                            </div>
                            <div className="section">
                                <RoadMap mobileSource={r4mb} videoSource={r4} ></RoadMap>
                            </div>
                        </>
                    );
                }}
            />
        </>

    )
}