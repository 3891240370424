import React, { useState } from "react";
import Popup from "reactjs-popup";
import card from '../../assets/videos/card.mp4';
import cardMobile from '../../assets/videos/cardmob.mp4';
import { startRachet, startMetal, startRoboClick } from "../_helper";
import "./Card.css";


export default function Card() {
  const [open, setOpen] = useState(0);
  const [type, setType] = useState(0);
  const [email, setEmail] = useState(0);
  const [twitter, setTwitter] = useState(0);
  const mobile = window.matchMedia("(max-width: 500px)")

  const sendTotelegram = (event) =>{
    setOpen(true);
    setType(event);
  }
 
  const onSubmit = (e) => {
    e.preventDefault();
    let message = "Contacted from "+ type + " with email "+email+ " and twitter "+twitter+"."
    let url = "https://api.telegram.org/bot2083506152:AAEqHd97lhTDp2LL9FT5FoAZQ3ix02pE2cE/sendMessage";
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
  };
    try{
      fetch(url+"?chat_id="+"-789628708"+"&text="+message, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.ok){
          setOpen(false);
          
        }
      });
    }catch(err){
        console.log(err);
    }
}

  const renderSecondPageContent = () => (
    <>
    <div className="background_message_button">
          <p>
            <a onMouseEnter={startMetal} onClickCapture={startRachet} className="glow" onClick={()=>sendTotelegram("Investor")}>Investor</a>
          </p>
          <p>
            <a onMouseEnter={startMetal} onClickCapture={startRachet} className="glow" onClick={()=>sendTotelegram("Developer")}>Developer</a>
          </p>
          <p>
            <a onMouseEnter={startMetal} onClickCapture={startRachet} className="glow" onClick={()=>sendTotelegram("Designer")}>Designer</a>
          </p>
          <Popup  open={open} onClose={()=>setOpen(false)} contentStyle={{backgroundColor:"transparent"}} position="right center">
              <div>
                <form className="info_form">
                  <input className="info_form_input" type="email" onChange={(e)=>setEmail(e.target.value)} placeholder="Email" required/>
                  <input className="info_form_input" type="text" onChange={(e)=>setTwitter(e.target.value)} name={twitter} placeholder="Twitter Handle" required/>
                  <input onClickCapture={startRoboClick} className="info_form_button" type="submit" onClick={onSubmit} value="Submit"/>
                </form>
              </div>
         </Popup>
    </div> 
    </>
    );

      return (
        <div className = "cardContainer">
            <video className="cardVideo" playsInline data-keepplaying autoPlay loop muted> {mobile.matches ? <source src={cardMobile} type="video/mp4" ></source> : <source src={card} type="video/mp4" ></source>} </video>
            {renderSecondPageContent()}
        </div>
      )
  
}