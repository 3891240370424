import { useWeb3React } from "@web3-react/core"
import { InjectedConnector } from "@web3-react/injected-connector"
import { ethers } from 'ethers'
import dsRpostenContract from '../../assets/contracts/DataSyndicateNFT.json'
import React, { useState,useEffect} from "react"
import BarLoader from "react-spinners/BarLoader"
import { css } from "@emotion/react"
import './connector.css'
import { startMetal, startConnect, startDisabled } from "../_helper"
import { useNavigate } from "react-router-dom";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`
export default function WalletConnector({setDilogOpen,setMintResult}) {
  const navigate = useNavigate();

  const { active, activate } = useWeb3React()
  const contractAddress = "0x17752F916f2206A2665fB5396B6C9267921D1b65";
  const abi = dsRpostenContract.abi;
  const mobile = window.matchMedia("(max-width: 500px)")
  
  let [loading, setLoading] = useState(false);
  let [remainingToken, setRemaining] = useState(0);
  let [color] = useState("#ffffff");
  const [balance, setBalance] = useState(0);
  useEffect(() => {
    try{
    const { ethereum } = window;
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const nftContract = new ethers.Contract(contractAddress, abi, signer);
      let totalSupply = nftContract.totalSupply().then(s=>{
        setRemaining((200-parseInt(s)));
      }).catch(err=>{console.log(err);})
    }catch(err){}
  }, [])
  
  async function connect() {
    const injected = new InjectedConnector({
        supportedChainIds: [1, 3, 4, 5, 42],
      })
    try {
      await activate(injected)
      const { ethereum } = window;
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const nftContract = new ethers.Contract(contractAddress, abi, signer);
      let totalSupply = await nftContract.totalSupply();
      setRemaining((200-parseInt(totalSupply)));
    } catch (ex) {
      console.log(ex)
    }   
  }

  const mintNftHandler = async () => {
    try {
      setLoading(true);
      const { ethereum } = window;
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);
        let resp = await nftContract.mint({ value: ethers.utils.parseEther('0.7') });
        await resp.wait();
        setLoading(false);
        let totalSupply = await nftContract.totalSupply();
        setRemaining((200-parseInt(totalSupply)));
        setDilogOpen(true);
        setMintResult("Success: See your minted NFT at https://www.opensea.io/assets/0xb62fF7F7A4E705C3297c2Fdfa4d56188F74E8703"+"    Transaction Hash: "+resp.hash)
      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      setLoading(false)
      setDilogOpen(true);
      setMintResult("Error: "+err.error.message)
    }
  }

  const appClick = () =>{
    navigate("/app")
  }

  return (
      <div>
        {active ? <div><span className="green" onMouseEnter={startMetal} onClickCapture={startConnect} onClick={mintNftHandler}>MINT 0.7 ETH</span>{mobile.matches ? <></> : <br/>}<span className="small">{remainingToken}/200 </span>{mobile.matches ? <></> : <br/>}<span title="Connect Wallet to Mint" onMouseEnter={startMetal} onClickCapture={startConnect} onClick={appClick} className="green app" >App</span></div>:
        <div><span className="green" onMouseEnter={startMetal} onClickCapture={startConnect} onClick={connect}> Connect Wallet </span> <br></br> {mobile.matches ? <br></br> : <></> }  <span title="Connect Wallet to Mint" className="red disabled" onClickCapture={startDisabled}>MINT</span>{mobile.matches ? <>  </>:<br/>}<span title="App" onMouseEnter={startMetal} onClickCapture={startConnect} onClick={appClick} className="green app" >App</span></div> }
        <BarLoader color={color} loading={loading} css={override}/>
      </div>
  )
}