import about from '../../assets/videos/about.mp4'
import aboutMobile from '../../assets/videos/aboutmob.mp4'
import discord from '../../assets/images/discord.png'
import github from '../../assets/images/github.png'
import telegram from '../../assets/images/tg.png'
import tw from '../../assets/images/tw.png'
import snapshot from '../../assets/images/snapshot.png'
import opensea from '../../assets/images/opensea.png'
import openseamob from '../../assets/images/new/opensea2.png'
import discordMobile from '../../assets/images/discord_mob.png'
import githubMobile from '../../assets/images/gh_mob.png'
import telegramMobile from '../../assets/images/tg_mob.png'
import twMobile from '../../assets/images/twit_mob.png'
import growthMap from '../../assets/videos/RoadMap/desktop/buttons_desktop/growth map.png'
import growthMapMobile from '../../assets/images/new/growth map1.png'
import snapshotMobile from '../../assets/images/snapshot_mob.png'
import WalletConnector from "../metamaskLink/connector"
import React, { useState,useEffect } from "react"
import Popup from "reactjs-popup";
import {startRachet, startMetal, startBackground} from '../_helper'
import "./about.css";
import { useModal } from 'react-hooks-use-modal';


export default function About({errorMessage}) {
  const [mintResult, setMintResult] = useState("");
  const [message, setMessage] = useState("--- Welcome to Data Syndicate ---");
  const [buttonMessage, setButtonMessage] = useState("Start");
  const [dilogOpen, setDilogOpen] = useState(false);
  const mobile = window.matchMedia("(max-width: 500px)")
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
    open:true,
  });

  const background = () => {
    startBackground()
    close();
  }
  useEffect(() => {
    if(errorMessage){
      setMessage(errorMessage);
      setButtonMessage("OK");
    }
    open();
  }, [errorMessage])
  
  const renderSocials= () => (
    <>
        <a target="_blank" onMouseEnter={startMetal} onClickCapture={startRachet} href="https://twitter.com/SyndicateData"> {mobile. matches ? <img className="glowAndlarge" src={twMobile}/> : <img className="glowAndlarge" src={tw}/> } </a>
        {mobile.matches ? <></>:<div>&nbsp;&nbsp;&nbsp;</div>}
        <a target="_blank" onMouseEnter={startMetal} onClickCapture={startRachet} href="https://github.com/DataSyndicateOrg"> {mobile. matches ? <img className="glowAndlarge" src={githubMobile}/> : <img className="glowAndlarge" src={github}/> } </a>
        {mobile.matches ? <></>:<div>&nbsp;&nbsp;&nbsp;</div>}
        <a target="_blank" onMouseEnter={startMetal} onClickCapture={startRachet} href=" https://telegram.me/collablandbot?start=VFBDI1RFTCNDT01NIy0xMDAxNDAyNjU3NTEx"> {mobile. matches ? <img className="glowAndlarge" src={telegramMobile}/> : <img className="glowAndlarge" src={telegram}/> } </a>
        {mobile.matches ? <></>:<div>&nbsp;&nbsp;&nbsp;</div>}
        <a target="_blank" onMouseEnter={startMetal} onClickCapture={startRachet} href="https://discord.gg/wnUrxRuhsk"> {mobile. matches ? <img className="glowAndlarge" src={discordMobile}/> : <img className="glowAndlarge" src={discord}/> } </a>
        {mobile.matches ? <></>:<div>&nbsp;&nbsp;&nbsp;</div>}
        <a target="_blank" onMouseEnter={startMetal} onClickCapture={startRachet} href="https://snapshot.org/#/thespacingguild.eth"> {mobile. matches ? <img className="glowAndlarge" src={snapshotMobile}/> : <img className="glowAndlarge" src={snapshot}/> } </a>
        {mobile.matches ? <></>:<div>&nbsp;&nbsp;&nbsp;</div>}
        <a target="_blank" onMouseEnter={startMetal} onClickCapture={startRachet} href="https://opensea.io/collection/datasyndicatenft"> {mobile. matches ? <img className="glowAndlarge" src={openseamob}/> : <img className="glowAndlarge" src={opensea}/> } </a>
        {mobile.matches ? <></>:<div>&nbsp;&nbsp;&nbsp;</div>}
        <a onMouseEnter={startMetal} onClickCapture={startRachet} href="/#RoadMap-1"> {mobile. matches ? <img className="glowAndlarge" src={growthMapMobile}/> : <img className="glowAndlarge" src={growthMap}/> } </a>
    </>
  )
  
    return (
      <div className="aboutContainer">
        <Popup open={dilogOpen} onClose={()=>setDilogOpen(false)} contentStyle={{backgroundColor:"transparent"}} position="right center">
          <form className="info_form">
            <input className="ok_form_input" type="text" value={mintResult} disabled></input>
            <input onMouseEnter={startMetal} onClickCapture={startRachet} className="ok_form_buttton" type="submit" onClick={()=>setDilogOpen(false)} value="Ok"/>
            </form>
          </Popup>
          <video playsInline data-keepplaying className="aboutVideo" autoPlay loop muted> {mobile.matches ? <source src={aboutMobile} type="video/mp4"></source> : <source src={about} type="video/mp4"></source> } </video>
          <div className="topLinks">
            {renderSocials()}
          </div>
          <div className="connectWalletBlock"><WalletConnector setMintResult={setMintResult} setDilogOpen={setDilogOpen}></WalletConnector></div>
          {mobile.matches ? <></> : 
              <Modal>
              <div>
                <div className="info_form">
                  <div className="welcome_input" >{message}</div>
                  <button className="start_button"  onClick={()=>background()} >{buttonMessage}</button>
                </div>
              </div>
            </Modal>
          }
      </div>
    )
  
}