import './RoadMap.css'
const mobile = window.matchMedia("(max-width: 500px)")

export default function RoadMap({ mobileSource , videoSource}) {
    
    return (
        <><div className="rmapVContainer">
             {mobile.matches ? <video playsInline data-keepplaying className="roadMapVideo" autoPlay loop muted> <source src={mobileSource} type="video/mp4"></source> </video> : <video data-keepplaying className="roadMapVideo" autoPlay loop muted> <source src={videoSource} type="video/mp4"></source> </video> }
        </div>
        </>
    )
}