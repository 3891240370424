import { Container } from "react-bootstrap"
import './WalletTrackerAppHome.css'
import React, { useState, useEffect } from "react";
import { ethers } from 'ethers'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { makeStyles } from '@mui/styles';
import walletImg from '../../assets/images/wallet.png'
import xLoad from '../../assets/images/xload.gif'
import { Alert, Dialog, DialogTitle, Divider, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Snackbar } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid'
import { createTheme } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { AccountBalanceWalletRounded, Logout, Person, PersonAdd } from "@mui/icons-material";

const useStyles = makeStyles({
    root: {
        marginTop: '20vh',
    },
});
export default function WalletTrackerAppHome({ setErrorMessage }) {
    const theme = createTheme({
        text: {
            primary: 'white',
            secondary: 'white',
        }
    })
    const navigateHome = useNavigate()
    const navigate = useNavigate();
    const [myAccountDialog, setMyAccountDialog] = useState(false);
    const [myWalletsDialog, setMyWalletsDialog] = useState(false);
    const [targetWallet, setTargetWallet] = useState('')
    const [anchorEl, setAnchorEl] = useState(null)
    const openProfileMenu = Boolean(anchorEl);
    const [loading, setLoading] = useState(true);
    const [walletAddress, setWalletAddress] = useState(false);
    const [data, setData] = useState({ wallets: [], isFetching: false })
    const [open, setOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const columns = [
        { field: 'pnl', headerName: 'PNL%', flex: 1 },
        { field: 'id', headerName: 'Wallet ID', flex: 1.5 },
        { field: 'createdBy', headerName: 'Updated By', flex: 0.5 },
    ];
    useEffect(() => {
        const { ethereum } = window;
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const fetchWallets = async () => {
            try {
                setData({ wallets: data.wallets, isFetching: true })
                const response = await axios.get('https://shz0jzwz63.execute-api.us-east-2.amazonaws.com/dev/wallets')
                console.log('Response:', response)
                setData({ wallets: response.data.Items, isFetching: false });
            } catch (e) {
                console.log('Error:', e)
                setData({ wallets: data.wallets, isFetching: false });
            }
        }
        fetchWallets()

        setTimeout(() => {
            fetchWallets()
        }, 60000);
        signer.getAddress().then(walletAddress => {
            setLoading(false)
            if (walletAddress) {
                setWalletAddress(walletAddress)
            }
        }).catch(err => {
            setErrorMessage("Wallet Not Connected.")
            navigate("/");
        })

    }, []);
    const onTrack = async (id, createdBy) => {
        try {
            await axios.post('https://shz0jzwz63.execute-api.us-east-2.amazonaws.com/dev/wallet', {
                "id": id,
                "createdBy": createdBy
            })
            setOpen(true)
            setToastMessage('Success')
        } catch (error) {
            setOpen(true)
            setToastMessage(error.response.data.error)
        }
    }

    function handleChange(e) {
        setTargetWallet(e.target.value)
    }

    function handleClose() {
        setOpen(false)
    }

    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleProfileClose = () => {
        setAnchorEl(null);
    };

    const filteredWallets = data.wallets.filter((wallet) => {
        return wallet.createdByWallet === walletAddress
    })

    return (
        <>

            {loading ? <Container fluid className="WalletTrackerAppHome">
                <div><img className="xLoad" src={xLoad} /></div>
            </Container> :
                <Container fluid className="WalletTrackerAppHome justify-content-center">
                    <Tooltip title="User Profile">
                        <IconButton
                            className="connectedAddress"
                            onClick={handleProfileClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={openProfileMenu ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openProfileMenu ? 'true' : undefined}
                        >
                            <Avatar><img className="walletImg" src={walletImg} /></Avatar>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={openProfileMenu}
                        onClose={handleProfileClose}
                        onClick={handleProfileClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >

                        <MenuItem
                            onClick={() => setMyAccountDialog(true)}
                        >
                            <ListItemIcon>
                                <Person fontSize="small" />
                            </ListItemIcon>
                            <span className="menuItem"> My Account </span>
                        </MenuItem>
                        <MenuItem
                            onClick={() => setMyWalletsDialog(true)}
                        >
                            <ListItemIcon>
                                <AccountBalanceWalletRounded fontSize="small" />
                            </ListItemIcon>
                            <span className="menuItem"> My Wallets </span>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                navigateHome('/#About')
                            }}
                        >
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            <span className="menuItem"> Logout </span>
                        </MenuItem>
                    </Menu>
                    <div className="trackWallet">
                        <input className="walletInput" type="text" value={targetWallet} onChange={handleChange}></input>&nbsp;
                        <button className="submitButton" disabled={!targetWallet} onClick={() => onTrack(targetWallet, walletAddress)}>Track Wallet Address</button>
                        <Dialog open={myWalletsDialog} onClose={() => setMyWalletsDialog(false)}>
                            <DialogTitle>My Wallets</DialogTitle>
                            <List>
                                {
                                filteredWallets.length > 0 ?
                                filteredWallets.map((wallet) => (
                                   <ListItem key={wallet.id}>
                                       <ListItemText primary={wallet.id}></ListItemText>
                                   </ListItem>
                                )) : <span>No wallets added</span>}
                            </List>
                                
                        </Dialog>
                        <Dialog open={myAccountDialog} onClose={() => setMyAccountDialog(false)}>
                            <DialogTitle>Account Details</DialogTitle>
                            <List>
                                <span className="dialogList"> My Address: {walletAddress}</span>
                            </List>                              
                        </Dialog>
                        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                            {toastMessage === 'Success' ?
                                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                                    {toastMessage}
                                </Alert> :
                                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                                    {toastMessage}
                                </Alert>
                            }
                        </Snackbar>
                    </div>
                    <div style={{ display: 'flex', height: 400, width: '100%', marginTop: 200 }}>
                        <div style={{ flexGrow: 1 }}>
                            {/* <ThemeProvider theme={theme}> */}
                            <DataGrid
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'pnl', sort: 'desc' }],
                                    },
                                }}
                                sx={{ color: 'white', columnRuleColor: 'white' }}
                                rows={data.wallets}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                disableSelectionOnClick
                                disableColumnMenu
                            />
                            {/* </ThemeProvider> */}
                        </div>

                    </div>
                </Container>

            }
        </>
    )
}